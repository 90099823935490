.paddings{
    padding-left: 5rem !important;
    padding-top: 3rem !important;
}
.content.column-direction.paddings{
    min-height: 47.5vh;
    .check-label{
        padding-left: 1rem;
        padding-bottom: 0 !important;
    }
    .check{
        align-items: center;
    }
    label{
        font-size: 1.4rem !important;
        line-height: 1.6rem;
        font-weight: 400!important;
        color: $color-medium-gray!important;
        padding-bottom: 1rem;
      }
      input, select{
            outline: none;
            border: 1px solid $color-light-gray;
            border-radius: 15px;
            padding-left: .5rem;
            padding-right: 1rem;
            height: 3.5rem!important;
            color: $color-dark-gray;
            font-size: 1.2rem;
            font-family: "Avenir-Light", sans-serif;    
      }
      .whole-input{
          margin: .5rem 1rem 1rem -0.5rem;
          width: 50%;
      }
      .duration-input{
          width:55%;
      }
}