.modal-class {

    background: #FFFFFF;
    min-height: 40vh;
    /* drop shadow */

    box-shadow: 0.1rem  0.1rem  4rem rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width:85%;
    min-width:110rem;
   
}
.ui.standard.modal.visible.active.upload-new{
    border-radius: 25px!important;
    background-image: url(../../assets/upload-modal-background.svg);
    background-size: cover;
    @media only screen and (min-width: 769px){
        width:90rem!important;
        padding:3rem 4rem!important; 
    }
    @media only screen and (max-width: 768px){
        width:80%!important;
        height: 65vh;
        padding:3rem 4rem!important; 
    }
}

.modal-class-error {

    background: #FFFFFF;
    min-height: 30vh;
    /* drop shadow */

    box-shadow: 0.1rem  0.1rem  4rem rgba(0, 0, 0, 0.1);
    border-radius: 2rem !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:45% !important;
    .modal-content{
        border-radius: 2rem !important;    
        display: flex!important;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 30vh;
        .modal-title{
            font-size: 3.4rem;
            font-weight: 800;
            color: $color-dark-gray;
        }

    }
    // min-width:110rem;
   
}
.modal-title{
    display: flex;
    color: $color-blue;
    font-size: 1.4rem;
    font-weight: 500;
    justify-content: center;
    margin: 2rem 0;
}
.admin-table.model{
    width: auto;
    // min-width: 80%;
    border-spacing: 0 .5rem;
    thead{
        width: auto;
        text-align: center;
    }
    .thead-row{
        color: $color-light-gray;
        width: 400%;
        margin-top: -.5rem;
    }
    .td{
        //    min-width: 6%;
            text-align: center !important;
            padding: 0rem;
            padding-left: 0rem;
            text-transform: capitalize;
            margin-left: auto;
            margin-right: auto;
            color: $color-medium-gray;
            font-size: 1.2rem;
            .direction-row{
                justify-content:center;
            }
        }
    .model-row{
        height: 3rem;
        &:hover{
            background-color: $color-off-white;
        }
    }
}

.bmId-input{
    outline: none;
    border: 1px solid $color-light-gray;
    border-radius: 15px;
    padding-left: .5rem;
    height: 2rem;
    color: $color-dark-gray;
    font-size: 1.2rem;
    font-family: "Avenir-Light", sans-serif;
    text-align: center;
}
.actions{
    .button-all{
        margin:1rem 2rem 1rem 0;
    }
    .button-create{
        margin: 1rem 1rem 1rem 2rem;
    }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 2s linear infinite;
  margin-top: 20px
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.table-holder{
    max-height: 50vh;
    overflow-x: auto;
}

.admin-table.model.select{
    width: 40vw;
    .thead-row{
        width: 300%;
    }
    .model-row.selected{
        background: $color-blue;
        .td{
            font-weight: 500;
            color:white;
        }
    }
}
.modal-title.select{
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 0;
}
.modal-subtitle{
    display: flex;
    justify-content: center;
    color: #556fb5;
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: .5rem;
    margin-bottom: 1rem;
}

.wrapper-override{
    padding:0px;
    @media (min-width:769px){
        height:25rem;
    }
    
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:27.5rem;
    border-radius:25px!important;
    
    // align-content: center;

    
    .close-div {
        z-index:999;
        height: 100%;
        width:3rem;
        height:3rem;
        position:absolute;
        margin-left: auto;
        // cursor: pointer;
        color: $color-medium-gray;
        float:right;

        .icon-modal {
            cursor: pointer;
            margin-left:  2rem;
            margin-bottom: 2rem;
            // color: #424B54;
            position: fixed;
            right: 0.2rem;
            top:0.2rem;
        }
        .icon-modal-new {
            cursor: pointer;
            margin-left:  2rem;
            margin-bottom: 2rem;
            position: fixed;
            right: 1rem;
            top:1rem;
        }
        .icon-modal:hover, .icon-modal-new:hover{
            color: #424B54;
            @extend .transitionEffect;
        }
    }

    .modal-upload-field{
        align-items: center;
        display: flex;
        justify-content: center;
        
        h5{

            font-family: "Avenir-Light", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 140%;
            color: #424B54;
            padding-top: 40%;
        }
    }

    .modal-delete-field {
        display: flex;
        height:100%;
        width:100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // align-content: center;
        // padding: 2.5rem;


        h5 {
            
            font-family: "Avenir-Light", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 140%;
            /* or 38px */
            margin: 3rem 0;
            
            /* Outer space */
            
            color: #424B54;
        }
        h3{
            font-family: "Avenir-Light", sans-serif;
            font-style: normal;
            font-weight: 500;
            text-align: center;
            font-size: 2rem;
            line-height: 160%;
            margin: 3rem 0px;
            color: rgb(66, 75, 84);
        }
        
        .h3-responsive{
            @media (max-width:500px){
                margin: 0px;
            }
        }
       
        
    
        p {
            font-family: "Avenir-Light", sans-serif;
            font-size: 1.6rem;
            display: flex;
            justify-content: center;
            // align-items: center;
            color: #424B54;
        }
        
        .bottom-text-modal {
       
            // display: flex;
            justify-content: center;
            align-items: center;
            margin-top: auto;
            // margin: 10px 0px;
            color: #424B54;
        }
        

    }
}
// Modal ID FILES
.idFiles{
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    div{
        margin-left: 2rem;
        // span{
        //     font-size: 12px;
        // }
        .usernameIdFiles{ 
            font-size: 14px;
        }
    }
}
.idFileInfo{
    height: 60vh;
    .jsoneditor-menu{
        background-color:#556fb5;
    }
    .jsoneditor-outer{
        height: 50vh;
        overflow: scroll;
    }
 
}
.infoIdbtn{
    display: flex;
    flex-direction: row-reverse;
    margin-right: 3rem;
    .update{
        background-color:#556fb5;
        color: white;
    }
 }