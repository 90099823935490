.dashboard{
    padding:2rem!important;
    background-color: $color-off-white;
    // min-height: 94%;
}
.td-first-round-edges{
    border-radius: 1.6rem 0 0 1.6rem;
    padding-left:2rem!important;
  }
.td-last-round-edges{
    border-radius: 0 1.6rem 1.6rem 0;
    padding-right:1rem!important;
    padding-left:1rem!important;
  }
.td-first-up{
    border-radius: 1.6rem 0 0 0rem;
    padding-left:2rem!important;
}
.td-first-down{
    border-radius: 0rem 0 0 1.6rem;
    padding-left:2rem!important;
}
.td-last-up{
    border-radius: 0 1.6rem 0rem 0;
    padding-right:1rem!important;
    padding-left:1rem!important;
}
.td-last-down{
    border-radius: 0 0rem 1.6rem 0;
    padding-right:1rem!important;
    padding-left:1rem!important;
}
.admin-table{
    width: $dashboard-width;
    margin-left: auto;
    margin-right: auto;
    border-spacing: 0 0rem;
    font-family: "Avenir-Light", sans-serif;
    .admin-head{
        margin-bottom: .5rem;
    }
    .t-head{
        color:$color-medium-gray;
        font-size: 1.1rem;
        padding-right: 0;
        padding-left: 0;
    }
    .primary-row{
        line-height: 3rem;
        background-color: white;
        margin-bottom: 2rem!important;
        
    }
    .td{
    //    min-width: 6%;
        @-moz-document url-prefix() {
            select{
                background-color: white;
                -moz-appearance:none;
            }
            input{
                background-color: white;
            }
        }
        padding: 1rem;
        padding-left: 2rem;
        text-align: center;
        select{
            outline:none;
            border:none;
            color: $color-blue;
            background: white;
        }
    }

    .td.select{
        outline:none;
        border:none;
        color: $color-blue;
        background: white;
        i{
            color: $color-dark-gray;
            &:hover{
                color: $color-medium-gray;
              }
        }
    }
    .td.transform{
        text-transform: capitalize;
    }
    .td-input{
        outline: none;
        border: none;
        border-bottom: solid 1px $color-blue !important;
        color: $color-blue;
        font-weight: 400;
        font-size: 1.2rem;
        text-align: center;
        max-width: 5rem;
    }
}
.icon.plus.cursore {
    height: auto;
}

.main-icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    width:2rem;
    i.icon{
        margin:0rem!important;
        height:100%!important;
        font-size:1.2rem!important;
    }
}

.fixed-width-mail{
    max-width: 16rem;
}
.transform{
    display: flex;
    max-width: 17rem !important;
    justify-content: space-between;
    align-items: center;
    margin-right: 0;
    padding-right: 0rem!important;
}
.start{
    display: flex;
    // justify-content: baseline;
    text-align: left;
}
.crop.start{
    overflow: hidden;
    
}


.secondary-row{
    @extend .transitionEffect;
    margin-top: -1rem !important;
    background-color: white !important;
    width: $dashboard-width;
}

.empty-row{
    height: 1rem;
}

.ui.menu:not(.vertical) .right.menu{
    .button-all {
        padding: 0 20px 0 20px;
        width: 20rem;
    }
    .button-create{
        margin-right:2rem;
    }
}

.dash-icon{
    height: 2rem;
    color: $color-off-white;
    filter: invert(50%) sepia(25%) saturate(0) hue-rotate(183deg) brightness(96%) contrast(88%);
    vertical-align: middle;
    
}

@media screen and  (min-width: 1000px) and (max-width:1360px) {
    .admin-table{
        width: 95vw;
        .td.company {
            display: none;
        }
        .td.hide {
            display: none;
        }
        .t-head.cursore.hide {
            display: none;
        }
        .t-head.hide {
            display: none;
        }
        td {
            font-size: 1.2rem;
            padding: 0;
        }
        .primary-row {
            line-height: 2rem;
        }
        .fixed-width-mail {
            max-width: 19rem;
        }
    }   
}