.content.direction-row{
    min-height: 60vh;
    display: flex !important;
    padding:1rem!important;
    .column-direction.half-width{
        padding-left: 5rem;
        padding-top: 5rem;
    }
    .check-label{
        padding-left: 1rem;
        padding-bottom: 0 !important;
    }
    .check{
        align-items: center;
    }
    label{
        font-size: 1.4rem !important;
        line-height: 1.6rem;
        font-weight: 400!important;
        color: $color-medium-gray!important;
        padding-bottom: 1rem;
      }
      input, select{
            outline: none;
            border: 1px solid $color-light-gray;
            border-radius: 15px;
            padding-left: .5rem;
            padding-right: 1rem;
            height: 3rem!important;
            color: $color-dark-gray;
            font-size: 1.2rem;
            font-family: "Avenir-Light", sans-serif;     
      }
      .whole-input{
          margin: .5rem 1rem 1rem -0.5rem;
          width: 90%;
      }
}
.half-width{
    width: 50%;
}

.pass-div{
    width: 47.5%;
}

.show-pass-btn {
    border: none;
    outline: none;
    background: transparent;
    margin-left: -2rem;
    height: 4.5rem;
    margin-top: .5rem;
    cursor: pointer;
    color: #979ead;
}

.registerKeys {
    font-size: 18px;
    color: rgb(126, 126, 136);
    font-family: "Avenir-Light", sans-serif;
}

.registerLocks {
    font-size: 16px;
    color: black;
    font-family: "Avenir-Light", sans-serif;
    border: none;
    &.padding {
        padding-left: 10px;
    }
}

.register-title{
    display: flex;
    color: #556fb5;
    font-size: 2.4rem;
    font-weight: 800;
    // margin: 5rem 0 0  5rem;
}
.register-subtitle{
    display: flex;
    color: #556fb5;
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: 1rem;
}
.input-div{
    margin-top: 1rem;
}


