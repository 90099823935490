.hidden{
    display:none!important;
}
.direction-row{
    display: flex;
    flex-direction: row;
}
.column-direction{
    display: flex;
    flex-direction: column;
}

.crop{
    overflow: auto;
}
.crop::-webkit-scrollbar {
    width: 1px!important;
  }
.cursore{
    cursor: pointer;
}
.centered{
    display: flex;
    justify-content: center;
    align-items: center;
}