.button-no-style{
    border: none;
    outline: none;
    background: transparent;
    align-items: baseline;
    // @extend .transitionEffect;
    .text{
        font-size: 1.3rem;
        font-weight: 500;
        color: #c7ccd7;
        margin: 0.1rem 0 0 0.5rem;
    }
    &:hover {
        i,.text{
            color:$color-medium-gray;
        }
    }
}
.on-icon{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
}