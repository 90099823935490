.button-create{
    font-family: "Avenir-Light", sans-serif;
    background: $color-blue;
    box-shadow: 0.01px 0.1rem 1rem rgba(0, 0, 0, 0.07)!important;
    // border-radius: 0.4rem;
    outline: none;
    border: none;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius:2rem!important;
    width: 22rem;
    height: 4.1rem;
    margin: 3rem 0.5rem 3rem 2rem;
    cursor: pointer;
    &:hover{
        background:$color-blue-hover;
      }
}

.button-all{
    color:$color-blue;
    box-shadow: 0.01px 0.1rem 1rem rgba(0, 0, 0, 0.07)!important;
    // border-radius: 0.4rem;
    outline: none;
    border: solid 1px $color-blue;
    background: #ffffff;
    font-size: 1.4rem;
    font-family: "Avenir-Light", sans-serif;
    font-weight: 500;
    width: 13rem;
    height: 4.1rem;
    margin: 3rem 2rem 3rem 0;
    cursor: pointer;
    border-radius:2rem!important;
    &:hover{
        background:#FBFCFF;
      }
}
// .button-all.email{
//   color:$color-blue;
//   box-shadow: 0.01px 0.1rem 1rem rgba(0, 0, 0, 0.07)!important;
//   // border-radius: 0.4rem;
//   outline: none;
//   border: solid 1px $color-blue;
//   background: #ffffff;
//   font-size: 1.4rem;
//   font-family: "Avenir-Light", sans-serif;
//   font-weight: 500;
//   width: 19rem;
//   height: 4.1rem;
//   margin: 3rem 2rem 3rem 0;
//   cursor: pointer;
//   border-radius:2rem!important;
//   &:hover{
//       background:#FBFCFF;
//     }
// }

.button-login{
  font-family: "Avenir-Light", sans-serif;
    background: $color-blue;
    box-shadow: 0.01px 0.1rem 1rem rgba(0, 0, 0, 0.07)!important;
    // border-radius: 0.4rem;
    outline: none;
    border: none;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius:2rem!important;
    width: 21rem;
    height: 4.1rem;
    margin: 3rem 0.5rem 3rem 2rem;
    cursor: pointer;
    &:hover{
        background:$color-blue-hover;
      }
}

.button-all.YesNo{
  color:$color-blue;
  box-shadow: 0.01px 0.1rem 1rem rgba(0, 0, 0, 0.07)!important;
  // border-radius: 0.4rem;
  outline: none;
  border: solid 1px $color-blue;
  background: #ffffff;
  font-size: 1.4rem;
  font-family: "Avenir-Light", sans-serif;
  font-weight: 500;
  width: 20rem;
  height: 4.1rem;
  margin: 3rem 2rem 3rem 0;
  cursor: pointer;
  border-radius:2rem!important;
  &:hover{
      background:#FBFCFF;
    }
}

.button-login.YesNo{
  font-family: "Avenir-Light", sans-serif;
    background: $color-blue;
    box-shadow: 0.01px 0.1rem 1rem rgba(0, 0, 0, 0.07)!important;
    // border-radius: 0.4rem;
    outline: none;
    border: none;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius:2rem!important;
    width: 20rem;
    height: 4.1rem;
    margin: 3rem 0.5rem 3rem 2rem;
    cursor: pointer;
    &:hover{
        background:$color-blue-hover;
      }
}

.button-solution{
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.6rem;
  box-shadow: 0.01px 0.1rem 1rem rgba(0, 0, 0, 0.07)!important;
  // margin: 0 4rem 4rem 0;
  height: 5rem;
  min-width: 22rem;
  padding: 1rem;
  z-index: 99;
  border-radius: 20px;
  color: #556fb5;
  background: #fff;
  font-family: "Avenir-Light",sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  outline:none;
  border: solid 1px $color-blue;
  .text{
    padding: 0 1rem;
  }
  &:hover{
    background:$color-off-white;
  }
}
.at{
  color: $color-at !important;
  border: solid 1px $color-at;
}
.at.selected{
  color:white !important;
  background: $color-at !important;
  border: solid 1px $color-at;
}
.sr{
  color: $color-sr !important;
  border: solid 1px $color-sr;
}
.sr.selected{
  color:white !important;
  background: $color-sr !important;
  border: solid 1px $color-sr;
}
.vs{
  color: $color-vs !important;
  border: solid 1px $color-vs;
}
.vs.selected{
  color:white !important;
  background: $color-vs !important;
  border: solid 1px $color-vs;
}
.as{
  color: $color-as !important;
  border: solid 1px $color-as;
}
.as.selected{
  color:white !important;
  background: $color-as !important;
  border: solid 1px $color-as;
}
// .button-solution.selected{
//   background: $color-medium-gray;
//   border-color: $color-dark-gray;
//   color: white;
  //
// }
.forbidden{
  cursor:no-drop !important;
}

@media screen and  (min-width: 1000px) and (max-width:1230px) {
    .button-solution {
      min-width: 20rem;
    }
}

.button-idFiles{
  text-transform: capitalize;
  box-shadow: 0.01px 0.1rem 1rem rgba(0, 0, 0, 0.07)!important;
  padding: 1rem;
  z-index: 99;
  border-radius: 20px;
  white-space: nowrap;
  font-size: 1.3rem;
  letter-spacing: 0.07rem;
  font-weight: 500;
  color: #556fb5;
  background: #fff;
  font-family: "Avenir-Light",sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  outline:none;
  border: solid 1px $color-blue;
  cursor: pointer;
  &:hover{
    background:$color-off-white;
  }
}
.button-idFiles.selected{
  background: #556fb5;
  color: #fff;
}

.idFileInfo{
  padding: 2rem;
  font-size: 2rem;
}
.dropCache{
  color: $color-cherry;
  border-color: $color-cherry;

}
