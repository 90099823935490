#root{
    height:100vh!important;
    width:100vw!important;
    background-color: $color-off-white;
}

.ui.modal > .content{
    font-family: "Avenir-Light", sans-serif !important;
}

.ui.secondary.menu.top{
    padding: 0 2rem 0 2rem;
    background-color: white;
}
.ui.secondary.menu .item{
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    align-items: center;
    color: $color-medium-gray !important;
    font-family: "Avenir-Light", sans-serif !important;
    
}
.ui.secondary.menu .item.logout{
    cursor: pointer;
    min-width: 10rem;
    justify-content: space-between;
}

.ui.standard.modal.visible.active.modal-class.upload-new.register{
    min-height: 65vh;
}
.ui.standard.modal.visible.active.modal-class.upload-new.add-sol{
    min-height: 55vh;
}

.content.models{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.ui.standard.modal.visible.active.basicc{
    border-radius:2rem!important;
    min-height: 20rem;
    
    @media only screen and (min-width: 769px){
        width:80rem!important;
    }
    @media only screen and (max-width: 768px){
        width:80%!important;
        height: 30%;
    }
}
@media screen and  (min-width: 1000px) and (max-width:1230px){
    .ui.standard.modal.visible.active.modal-class.upload-new.register {
        min-width: 90% !important;
    }
}

@media screen and  (min-width: 1919px) and (max-width:2560px){
    .ui.secondary.menu {
        padding: 0 30px 0 30px;
    }
}


@media screen and  (min-width: 1919px) and (max-width:2000px){
    .ui.secondary.menu {
        padding: 0 12px 0 12px;
    }
}
