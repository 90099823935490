.direction-row.first.analytics{
    margin: 5rem 0 2rem 5rem;
}

.direction-row.second.analytics{
    margin: 3rem 0 2rem 5rem;
}


.clients-activity{
    width: 100%;
    height: 29vh;
    padding: 3rem;
    background: white;
    border-radius: 2rem;
    z-index: 1000;
}

.frequent-hours,.images-info{
    position:relative;
    width:100%;
    height: 37.5vh;
    padding:3rem 4rem;
    background: white;
    border-radius: 2rem;
}

.images-info.at{
    align-items: center;
}

.images-info{
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    
    .logo-container{
        position: absolute;
        bottom:2rem;
        // left:10%;
        width:100%;
        height:3rem;
        display:flex;
        flex-direction: row;
        // align-items: center;
        // justify-content: center;
        img{
            width: auto;
            height:2rem;
            margin:auto 0.5rem auto 0;
        }
        .black-title{
            width: 60%;
            margin:auto 0;
        }
    }

}

.images-info-analytics{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .column-direction{
        padding: 0rem 2rem 1rem 0rem;
    }
}

.hr-analytics{
    border-top: solid $color-blue 1px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.frequent-hours{
    // background-image: url(../../assets/upload-modal-background.svg);
    background-size: cover;
    padding:2rem;
    height:31vh;
    z-index: 1000;

    .content{
        padding:2rem;
        border-radius: 2rem;
        background: white;
        height:100%;
        width:100%;
    }
}

.remaining-plan{
    margin-right:2rem;
    position: relative;
    width:60%;
    // min-width: 350px;
    height: 40vh;
    border-radius: 2rem;
    padding:2rem;
    background-image: url(../../assets/upload-modal-background.svg);
    background-size: cover;
    .content{
        padding:2rem 2rem 3rem;
        border-radius: 2rem;
        background: white;
        height:100%;
        width:100%;
    }
    .column-direction{
        justify-content: space-between;
        align-items:center;
    }
    .row-direction{
        height: 100%;
        justify-content: space-around;
    }
    .white-gray-text{
        position: absolute;
        bottom:8%;
        font-size: 1rem !important;
        // font-weight: bold!important;
        color: $color-light-gray!important;
        @media screen and (max-width:1920px){
            bottom: 11%;
        }
    }
    .hide-percent{
        .ant-progress-text{
            display:none
        }
    }
    .anticon.anticon-check{
        display:flex;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .ant-progress-circle-gradient{
        position: relative;
        width:12rem!important;
        height:12rem!important;
        font-size:2.4rem!important;
        @media (max-width:769px){
            width:8rem!important;
            height:8rem!important;
        }
    }
}

.zoomer{
    zoom: 1.1;
    @media screen and (min-width:1921px) {
        zoom: 1.69;
    }
}

.modal-h3 {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    text-align: center;
    font-size: 2rem;
    line-height: 160%;
    margin: 3rem 0px;
    color: #424b54;
}

.clients-activity,.remaining-plan,.frequent-hours,.images-info{
    .title{
        font-size: 1.4rem !important;
        line-height: 1.6rem;
        font-weight: bold!important;
        color: $color-blue!important;
        display: flex;
        align-items: center;
        text-transform: capitalize;
    }
    .gray-title{
        font-size: 1.3rem !important;
        line-height: 1.6rem;
        font-weight: bold!important;
        color: $color-medium-gray;
        padding-bottom: 2rem;
        // margin-bottom:2rem;
    }
    .gray-title.up{
        padding:0;
    }
    .black-title{        
        font-size: 1.2rem !important;
        line-height: 1.6rem;
        font-weight: bold!important;
        color:$color-black;
        margin-bottom:2rem;
    }
    .blue-title{
        font-size: 1.2rem !important;
        line-height: 1.6rem;
        font-weight: bold!important;
        color:$color-blue;
        margin-bottom:2rem;
        padding-top: 2rem;
    }
    .status-radial{
        border-radius: 2rem;
        margin-left: 2rem;
        font-size:1.2rem;
        font-weight:bold;
        color:$color-light-gray;
        background-color: $color-off-white;
        display: flex;
        text-align: center;
        justify-content: center;
        padding:0.2rem 2rem;
    }
    b.purple{
        color: $color-blue!important;
    }
    b.orange{
        color:#ed7a57!important;
    }
    b.green{
        color: $color-sr!important;
    }
    b.red{
        color:red;
    }
    b.yellow{
        color:#f4be4a;
    }
}

.dashContainer.analytics{

    @media (max-width:900px){
        height: 90vh;
        margin-right:-3rem;
        overflow: hidden;
        padding-right: 3rem;
        .row-direction.first.analytics,.row-direction.second.analytics{
            flex-direction:column;
        }
        .remaining-plan, .images-info,.frequent-hours ,.clients-activity{
            width: 100%!important;
        }
        .frequent-hours ,.clients-activity{
            margin:2rem 0!important;
        }

        &:hover{
            overflow-y: scroll;
            padding-right: 2rem; margin-right:-3rem;
        }
    }   
  }

.titles {
    padding: 1rem 3rem;
    justify-content: space-between;
}

.sol-info-wrapper{
    padding: 3rem 0 0 3rem;
}

.sol-info{
    max-height: 60vh;
    overflow-y: auto;
    
    
}

.analytics-row{
    height: 4rem;
    padding: 1rem 2.5rem;
    border-radius: 25px;
    box-shadow: 0.1rem .1rem 1rem rgba(0, 0, 0, 0.07)!important; 
    width: 100%;
    border: solid 1px $color-light-gray;
    font-size: 1.5rem;
    align-items: center;
    margin: .4rem 3rem .4rem 0rem;
    p{
        margin:0;
    }
    .key{
        text-transform: capitalize;
    }
    select{
        outline:none;
        border:none;
        color: $color-blue;
        background: white;
    }
    .td-input{
        outline: none;
        border: none;
        border-bottom: solid 1px $color-blue !important;
        color: $color-blue;
        font-weight: 400;
        font-size: 1.2rem;
        text-align: center;
        max-width: 5rem;
        background-color: white;
    }
}

.button-all-monthlyUsage {    
    background: #918BC2;
    color: white;
    box-shadow: 0.01px 0.1rem 1rem rgba(0, 0, 0, 0.07)!important;
    // border-radius: 0.4rem;
    float: left;
    outline: none;
    border: solid 1px $color-blue;
    font-size: 1.4rem;
    font-family: "Avenir-Light", sans-serif;
    font-weight: 500;
    width: 17rem;
    height: 4.1rem;
    margin: 1rem 2rem 1rem 0;
    cursor: pointer;
    border-radius:2rem!important;
}

@media screen and  (min-width: 1000px) and (max-width:1230px) {
    .analytics-row {
        font-size: 1.9rem;
    }
}