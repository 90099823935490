.ui.icon.input.search-user{
    font-family: "Avenir-Light", sans-serif !important;
    width: 24rem;
    height: 4.1rem;
    margin: 3rem 0.5rem 3rem 2rem;
    outline: none;
    input{
        border-radius: 25px;
    }
    ::placeholder {
        font-size: 1.1rem;
    }
}

.pagination{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    .pagination-item{
        margin: .5rem;
        display:flex;
        flex-direction: column;
        cursor: pointer;
        position: relative;
        // justify-content: center;
        align-items: center;
    }
    .circle-unit{
        display: flex;
        background: #EBEEF5;
        width: 3.5rem;
        height: 3rem;
        border-radius: 15px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        &.sm{
            background: #DAF2F2;
          }
          &.vs{
            background: #EBEEF5;
          }
        
       
    }
    .circle-unit-selected{
        display: flex;
        background: $color-blue;
        width: 3.5rem;
        height: 3rem;
        border-radius: 15px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
    
       
    }
    
    .selected-page{
        display:flex;
        height:0.5rem;
        width:80%;
        position: absolute;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background: $color-purple;
        bottom:0px;
        margin-bottom:1rem;;
    }
    
    
    .selected-unit{
        // margin:1rem;
        font-family: "Avenir-Light", sans-serif ;
        font-size: 1.6rem;
        color: $color-white;
        font-weight: bolder;
        cursor: pointer;
    }
    .option-unit{
        margin:.5rem;
        font-family: "Avenir-Light", sans-serif ;
        font-size: 1.6rem;
        color: $color-purple;
        font-weight: bolder;
        cursor: pointer;
        
    }
}
// pagination scroll
.scroll{
    display:flex; 
    overflow-x:scroll;
    max-width:230px;
    margin: 0 1rem;
    margin-bottom: 1rem;
  }
  .scroll::-webkit-scrollbar {
    width: 10px;
    height: 2.5px;
  }
  .scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #EBEEF5;
    border-radius: 10px;
  }
  .scroll::-webkit-scrollbar-thumb {
    background: $color-at;
    border-radius: 10px;
    width: 70px;

  }
  .option-unit-gray{
    color: #979ead;
  }

.searchOption {
    margin: 3rem 0.5rem 3rem 2rem;
    border-radius: 30px;
    padding: 0 10px;
    border: 1px solid rgba(34,36,38,.15);
    cursor: cursor;
    outline: none;
}
@media screen and (max-width: 1113px) {
  .scroll{
    max-width: 200px;
  }}
@media screen and (max-width: 835px) {
  .scroll{
    max-width: 180px;
}} 
