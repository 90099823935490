
.centerThis{
    align-items: center;
    display: flex;
    justify-content: center;
    padding:10px;
    margin:auto;
   
  }
  .loginbox {
   
    background: #ffffff;
    box-shadow: $small-shadow-light;
    border-radius: 4px;
    padding: 1rem;
    // position:absolute;
    // left:50%;
    // top:50%;
    // transform: translate(-50%,-50%);
    
  }
  .styledRow{
    
    align-items: center;
    display: flex;
    justify-content: center;
    margin:auto;
    background: #ffffff;
    padding: 4rem 5rem;
    border-radius: 25px;
    box-shadow: $big-shadow-light;
    // position: relative;
    
  }
  .formField {
    width: 37rem;
    height: 5rem;
    opacity: 0.5;
  
    padding: 1.5rem 3rem!important;
  
    box-sizing: border-box;
    border: 0.2rem solid #8e9aaf;
    border-radius: 25px!important;
    font-size: 1.4rem !important;
  }
  
  
  .styledLabel {
    font-size: 1.6rem !important;
    line-height: 2.2rem;
    color: #424b54;
  }
  .styledLabelTop {
    font-size: 1.7rem !important;
    line-height: 2.2rem;
    color: #424b54;
    // @extend .styledLabel;
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
  
  }
  
  .styledBottomText{
  
    & p:first-child {
        font-size: 1.6rem;
        line-height: 2.5rem;
        color: #424b54;
        margin-bottom: 3.3rem;
        font-weight: 100;
        text-align: center;
      }
    
      & p:last-child {
        font-size: 1.4rem;
        line-height: 2rem;
        color: #424b54;
      }
  
  }
  
  img.logoImage {
    position: absolute;
    left: 0rem;
    top: 0rem;
    height: 5rem;
    // width:20rem;
    cursor: pointer;
    margin-left: 2rem;
    margin-top: 1rem;
    
  }
  
  .styledLeftFigure {
      width: 26rem;
    height: 26rem;
    border-radius: 50%;
    background: linear-gradient(180deg, #edae49 0%, #db2763 86.1%);
    opacity: 0.6;
    transform: rotate(-140.36deg) translate(100%,5%);
    position: absolute;
    // transform: translate(0, -50%) ;
    // left: 28%;
    z-index: -1;
  }
  
  .styledRightFigure{
      width: 35rem;
      height: 41rem;
      border-radius: 28%;
      background: linear-gradient(
        63.93deg,
        rgba(0, 191, 178, 0.7) 29.31%,
        rgba(255, 231, 76, 0.7) 112.55%);
      opacity: 0.6;
      transform: translate(35%, 1%) rotate(100.69deg);
      z-index: -1;
      overflow: hidden;
      position: absolute;
  }
  
  .login-div{
    margin:0!important;
    width:100%!important;
    height:100%!important;
    position:relative!important;
    background: #f8f9fb;
  }
  
  .login-form-bg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:70%;
    height:80%;
    padding:3rem;
    border-radius: 25px!important;
    background-image: url(../../assets/upload-modal-background.svg);
    background-size: cover;
    @media (max-width:768px) {
      width:90%;
      height:80%;
    }
    @media (max-height:500px) {
      height:95%;
      .formField{
        height:4rem!important;
      }
    }
    @media (max-height:400px) {
      height:95%;
      .ui.divider{
        margin:0.5rem 0!important;
      }
      .formField{
        height:4rem!important;
      }
      p{
        padding:0!important;
      }
    }
  }
  
  .login-form{
    height:100%;
    width:100%;
    border-radius: 25px;
    background-color: #ffffff;
    position:relative;
  }
  
  .login-img{
    @media(min-width:1100px){
      position:absolute;
      left:1%;
      bottom:0%;
      width: 42%!important;
      height: 100%;
      img{
        position:absolute;
        bottom:0%;
        height: auto;
        width: 100%;
      }
    }
    @media (max-width:1099px) {
      display:none;
    }
  }
  
  .login-input{
    font-family: "Avenir-Light", sans-serif !important;
    position:absolute;
    @media(min-width:1100px){
      left:50%;
      width: 50%;
      height: 100%;
      padding:2rem;
    }
    @media (max-width:1099px) {
      width: 100%;
      height: 100%;
      padding:3rem;
    }
    label{
      font-size: 1.4rem !important;
      line-height: 1.6rem;
      font-weight: 400!important;
      color: $color-medium-gray!important;
      padding-bottom: 1rem;
    }
    h1{    
      font-size: 1.8rem !important;
      line-height: 1.6rem;
      font-weight: bold!important;
      color: $color-blue!important;
      padding-bottom: 0.5rem;
  
    }
    p{
      font-size: 1.4rem !important;
      line-height: 2rem;
      font-weight: 600!important;
      color: $color-blue!important;
      padding-bottom: 1rem;
    }
    a{
      font-weight: 900!important;
      color: $color-blue!important;
      text-decoration: underline;
    }
    .ui.divider{
      // border-bottom: 0.2px solid $color-blue!important;
      background-color: $color-blue!important;
      height:1px!important;
      margin:3rem 0;
    }
    .column-direction{
      height:100%;
      justify-content: center!important;
      .ui.form{
        // height:100%!important;
        display:flex!important;
        flex-direction:column!important;
        justify-content: space-around!important;
      }
    }
  }
  